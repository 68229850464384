import { joinURL } from "ufo";
import type { ProviderGetImage } from "@nuxt/image-edge";

import { createOperationsGenerator } from "#image";

const operationsGenerator = createOperationsGenerator({
  // https://glide.thephpleague.com/2.0/api/quick-reference/
  keyMap: {
    width: "w",
    height: "h",
    format: "fm",
    quality: "q",
  },
  joinWith: "&",
  formatter: (key: any, value: any) => (String(value) === "true" ? key : `${key}=${value}`),
});

export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}, params) => {
  const operations = operationsGenerator(modifiers);

  const baseURL = 'https://network.nextpark.dev/v1/network/image';
  // const baseURL = 'http://network.nextpark.localhost/v1/network/image';

  for (const alias in params.options.alias) {
    src = src.replace(params.options.alias[alias], alias);
  }

  return {
    url: joinURL(baseURL, "?src=" + src + (operations ? "&" + operations : "")),
  };
};
